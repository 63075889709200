$(document).ready(function() {
    var icon = $('.play');
    var playButton = document.querySelector("#play");

    $("#mobile-navbar-pause").hide();
    $("#desktop-navbar-pause").hide();

    $('#mobile-navbar-play').click(function() {
        streamPlayStop($('#DawnAudioStream'));
        $("#mobile-navbar-pause").show();
        $("#mobile-navbar-play").hide();
        icon.toggleClass('active');
    });

    $('#desktop-navbar-play').click(function() {
        streamPlayStop($('#DawnAudioStream'));
        $("#desktop-navbar-pause").show();
        $("#desktop-navbar-play").hide();
        icon.toggleClass('active');
    });

    $('#mobile-navbar-pause').click(function() {
        streamPlayStop($('#DawnAudioStream'));
        $("#mobile-navbar-pause").hide();
        $("#mobile-navbar-play").show();
        icon.toggleClass('active');
    });

    $('#desktop-navbar-pause').click(function() {
        streamPlayStop($('#DawnAudioStream'));
        $("#desktop-navbar-pause").hide();
        $("#desktop-navbar-play").show();
        icon.toggleClass('active');
    });

    icon.click(function() {
        streamPlayStop($('#DawnAudioStream'));

        if (playButton.classList.contains("active")) {
            $("#mobile-navbar-pause").hide();
            $("#mobile-navbar-play").show();
        } else {
            $("#mobile-navbar-pause").show();
            $("#mobile-navbar-play").hide();
        }

        icon.toggleClass('active');
        return false;
    });
});

function streamPlayStop(audioElement) {
    if (audioElement[0].paused) {
        audioElement[0].play();
    }
    else if (!audioElement[0].volume) {
        audioElement[0].volume = 1;
    }
    else {
        audioElement[0].volume = 0;
    }
}
